<div class="container aviso-privacidad">

    <div class="row">
      <h1 class="title">
        {{ avisoPrivacidad.title }}
      </h1>
    </div>
  
    <div class="text-wrapper">
      <p *ngFor="let p of avisoPrivacidad.content">{{ p }}</p>
    </div>
  </div>