<div class="container publicaciones-container">

    <div class="row row-titles">
  
      <div class="title-wrapper">
        <div class="red-border-left"></div>
        <div class="red-border-right"></div>
        <h3 align="center">{{ subtitle }}</h3>
      </div>
      
    </div>
  
    <div class="row">
  
      <div class=" col-xl-4 col-lg-4 col-md-6 col-12" *ngFor="let publicacion of listaPublicaciones | sort:'orden' ">
        <a class="p-card" [routerLink]="[publicacion.plantilla, publicacion.name]">
          <img src="{{publicacion.url}}" alt="">
          <h3 
          *ngIf="publicacion.name != 'Miedo a morir' "  
          [innerHTML]="publicacion.name"></h3>
          <h3 
          *ngIf="publicacion.name === 'Miedo a morir' "  
          [innerHTML]="publicacion.second_title"></h3>
          <h2>{{ publicacion.section }}</h2>
          <p>{{ publicacion.description }}</p>
        </a>
      </div>
    </div>

    <!--
    <div class="row">
      <ng-adsense
        [adClient]="'ca-pub-2315453864859885'"
        [adSlot]="1325108310"
        [display]="'block'"
        [fullWidthResponsive]="true"
        [adFormat]="'auto'"
      ></ng-adsense>
    </div>
    -->
  
  </div>