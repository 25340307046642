import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plantilla3',
  templateUrl: './plantilla3.component.html',
  styleUrls: ['./plantilla3.component.scss']
})
export class Plantilla3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
