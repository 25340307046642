import { Component, OnInit } from '@angular/core';
import { Title, Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-aviso-privacidad',
  templateUrl: './aviso-privacidad.component.html',
  styleUrls: ['./aviso-privacidad.component.scss']
})
export class AvisoPrivacidadComponent implements OnInit {

  public avisoPrivacidad = {
    title: 'Aviso de privacidad',
    content: [
      'José Luis Zapata Torres, es el responsable del tratamiento de los datos personales que nos proporcione.',
      'Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades: Proporcionar información sobre novedades y promociones. Invitaciones a eventos y convocatorias.',
      'En caso de que no desee que sus datos personales sean tratados para las finalidades adicionales, usted puede manifestarlo mediante correo electrónico a la dirección de contacto.',
      'Si usted no manifiesta su negativa para dichas transferencias, se entenderá que ha otorgado su consentimiento.'
    ]
  }

  constructor(
    private metaTitle: Title,
    private meta: Meta
  ) { }

  ngOnInit() {
    //This section is for handling the meta data for SEO
    this.metaTitle.setTitle('Reverberante | Aviso de provacidad');
    this.meta.addTags([
      { name:'keywords', content:'Reverberante, Revista Reverberante, Revista cultura, Cultura, Magazine, Culture, revista independiente'},
      { name:'description', content:'Revista cultura Reverberante. Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades: Proporcionar información sobre novedades y promociones.'}
    ]);
  }

}