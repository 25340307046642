import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { LinksService } from '../../services/links.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { WindowRefServiceService } from '../../services/window-ref-service.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public links = [];
  public href;

  public mobile:boolean = false;
  public mobileM:boolean = false;

  constructor(
    private router:Router,
    private linkService:LinksService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefServiceService
  ) { 

    this.links = this.linkService.getLinks();

    if(isPlatformBrowser(this.platformId)){
      this.updateNavbar(this.windowRefService.nativeWindow.innerWidth);
      this.windowRefService.nativeWindow.addEventListener('resize', () => {
        this.updateNavbar(this.windowRefService.nativeWindow.innerWidth);
      });
    }

    this.href = this.document.location.pathname;
  }

  ngOnInit() {
  
  }

  public updateNavbar(size){
    //console.log(size);
    if (size < 700) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  public mobileMenu() {
    this.mobileM = !this.mobileM;
  }

  public navigateTo(url){
    //console.log(url);
    this.document.location.href = url
    //this.router.navigate([url]);
  }

}