import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PublicacionesComponent } from './components/publicaciones/publicaciones.component';
import { Plantilla1Component } from './components/plantilla1/plantilla1.component';
import { Plantilla2Component } from './components/plantilla2/plantilla2.component';
import { Plantilla3Component } from './components/plantilla3/plantilla3.component';
import { HomeComponent } from './components/home/home.component';
import { AvisoPrivacidadComponent } from './components/aviso-privacidad/aviso-privacidad.component';


const routes: Routes = [
  {path:'', component: HomeComponent},
  {path:'publicaciones',component: PublicacionesComponent},
  {path:'contacto',component: HomeComponent},
  {path:'aviso-privacidad',component: AvisoPrivacidadComponent},
  {path: 'publicaciones/1/:id', component: Plantilla1Component},
  {path: 'publicaciones/2/:id', component: Plantilla2Component},
  {path: 'publicaciones/3/:id', component: Plantilla3Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
