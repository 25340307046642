<div class="header">
    <div class="img">
      <!--Logo grande-->
      <img (click)="navigateTo('/')" class="b-logo" [ngClass]="{'hide':mobile}" src="https://drive.google.com/uc?export=view&id=1t_Hlwxb8ulkMKM1n8LZkk3wqETD5WDOI" alt="">
      <!--Logo pequeño-->
      <img (click)="navigateTo('/')" class="l-logo" [ngClass]="{'hide':!mobile}" src="https://drive.google.com/uc?export=view&id=19PFm0awVOv7Bgc2qJrVyeBVx_Pcldl4L" alt="">
    </div>
  
    <div class="nav-links" [ngClass]="{'hide':mobile}">
      <div *ngFor="let item of links" class="link">
        <a href="{{ item.path }}">{{item.name}}</a>
      </div>
    </div>
  
    <div class="hamburger-menu" [ngClass]="{'hide':!mobile, 'cross':mobileM }" (click)="mobileMenu()" > 
      <div class="line-wrapper">
          <div class="line one"></div>
          <div class="line two"></div>
          <div class="line three"></div>
      </div>
    </div>
  
  </div>
  
  
  <div class="mobile-menu hide" [ngClass]="{'hide':!mobileM}">
    <div class="links">
      <div *ngFor="let item of links" class="link">
        <a href="{{ item.path }}" (click)="mobileMenu()" [ngClass]="{'active-link':item.path===href}">{{item.name}}</a>
      </div>
    </div>
  
  </div>