import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { Title, Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-publicaciones',
  templateUrl: './publicaciones.component.html',
  styleUrls: ['./publicaciones.component.scss']
})
export class PublicacionesComponent implements OnInit {

  public title="YO SOY : SOMOS";
  public subtitle = "Empecemos el diálogo";
  public listaPublicaciones = [];

  constructor(
    private publicaciones: ProjectService,
    private metaTitle: Title,
    private meta: Meta
  ) {}

  ngOnInit() {
    //This section handles the list of articles
    this.publicaciones.getListaPublicaciones().subscribe(items => {
      items.forEach(publicacion => {
        
        this.listaPublicaciones.push(publicacion.payload.doc.data());
      });
      function comparar(a,b){return a.orden - b.orden;}
      this.listaPublicaciones.sort(comparar);
      this.listaPublicaciones.reverse();
      //console.log(this.listaPublicaciones);
    });

    //This section is for handling the meta data for SEO
    this.metaTitle.setTitle('Reverberante | Publicaciones');
    this.meta.addTags([
      { name:'keywords', content:'Reverberante, Revista Reverberante, Revista cultura, Cultura, Magazine, Culture, revista independiente'},
      { name:'description', content:'Revista cultura Reverberante. Lo imaginado, lo que nos pertenece y no, porque lo perdimos en el tiempo y lo seguimos buscando en el espacio pero que es necesario que lo creamos nuestro...'}
    ]);
  }

}