<div class="container container-plantilla" *ngIf="publicacion">

    <div class="row">

      <ng-adsense
        [adClient]="'ca-pub-2315453864859885'"
        [adSlot]="1325108310"
        [display]="'block'"
        [fullWidthResponsive]="true"
        [adFormat]="'auto'"
      ></ng-adsense>

    </div>

    <div class="row row-titles">
      <h2 class="title" [innerHTML]="publicacion.title"></h2>
      <h3 class="subtitle">{{ publicacion.author }}</h3>
    </div>
  
    <div class="row row-content">
      <div class="col-xl-8 col-lg-8 col-12 text-wrapper" [innerHTML]="publicacion.text"></div>

      <div class="col-xl-4 col-lg-4 col-12">

        <div class="top-content">
          <div *ngIf="publicacion.section == 'Reseñas'" class="poster-wrapper">
            <img src="{{publicacion.url}}" alt="">
            <p align="center">{{ publicacion.ilustrador }}</p>
            <div class="" *ngIf="publicacion.enlace">
              <a href="{{ publicacion.enlace }}">¡Adquiérelo aquí!</a>
            </div>
          </div>

          <div class="semblanza-wrapper" *ngIf="publicacion.semblanza != '' ">
            <p class="sem-title">Semblanza</p>
            <p class="sem-content" [innerHTML]="publicacion.semblanza"></p>
          </div>
        </div>

      </div>

    </div>
  
    
    <div class="row row-recomendaciones">
      <h2 class="title">{{ recomendacion }}</h2>
    </div>
  
    <div class="row row-recomendaciones">
      <div class="col-xl-4 col-lg-4 col-md-6 col-12" *ngFor="let rec of recomendaciones">
        <a routerLink="/publicaciones/{{rec.plantilla}}/{{rec.title}}" class="p-card" (click)="refresh()">
          <img src="{{rec.url}}" alt="">
          <h3>{{ rec.title }}</h3>
        </a>
      </div>
    </div>
  
  </div>