import { Injectable } from '@angular/core';

function getWindow (): any {
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class WindowRefServiceService {

  constructor() { }

  get nativeWindow (): Window {
    return getWindow();
  }
}
