import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  public links = [
    {
      name: 'Inicio',
      path: '/'
    },
    {
      name: 'Blog',
      path: '/publicaciones'
    },
    {
      name:'Eventos',
      path:'/#eventos'
    },
    {
      name: 'Convocatorias',
      path: '/#convocatorias'
    },
    {
      name: 'Tienda',
      path: 'https://www.tienda.reverberante.com/'
    }
  ];

  constructor() { }

  public getLinks(){
    return this.links;
  }
}
