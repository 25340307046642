<div class="container footer">
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-12 column">
        <a href="/aviso-privacidad">Aviso de privacidad</a>
        <a href="">Política legal</a>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-12 column">
        <p>revista.reverberante@gmail.com</p>
        <a href="https://www.facebook.com/Reverberante-827137894328788/"><span><img src="https://drive.google.com/uc?export=view&id=1Ue9_P65yk7Q5Iij1YArK_9cGGP8uGt0A" alt=""></span>Reverberante</a>
        <a href=""><span><img src="https://drive.google.com/uc?export=view&id=1zl8-CO-4j8gjBTyhSH3n3FGg9A4af0Xr" alt=""></span>@RevistaReverberante</a>
        <a href="https://www.instagram.com/revistareverberante/"><span><img src="https://drive.google.com/uc?export=view&id=11yOOV6j-XdgaPyn4MBtWNJn5xBZ9Xx9n" alt=""></span>@RReverberante</a>
      </div>
    </div>
  
    <div class="row powered-by">
      <div class="col-12">
        <p>Powered by</p>
        <img src="../../../assets/images/trueno.png" alt="">
        <p>Alejandro Zapett</p>
      </div>
    </div>
  </div>