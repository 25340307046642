import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  public listaPublicaciones = [];

  constructor(
    public db: AngularFirestore
  ) { }

  public getListaPublicaciones() {
    return this.db.collection('publicaciones').snapshotChanges();
  }

  public getPublicacion(name){
    return this.db.collection('contenido', ref => ref.where('title', '==', name)).snapshotChanges();
  }

}
