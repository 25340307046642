import { Component, OnInit, PLATFORM_ID, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { LinksService } from '../../services/links.service';
import { Title, Meta} from '@angular/platform-browser';
import { ProjectService } from '../../services/project.service';
import { WindowRefServiceService } from '../../services/window-ref-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  public title = "Efecto efímero";
  public description = "La palabra “Reverberante” hace alusión al efecto sonoro de la reverberación ―similar al eco―, el cual se produce inmediatamente después de que un objeto provoca un sonido dentro de un espacio; dicho efecto es efímero una vez que la fuente de origen ha dejado de producirlo, pero permanece en el inconsciente de los oyentes. Este eco (cultural) es precisamente lo que va a generar entre sus lectores la revista Reverberante. Como grupo editorial, buscamos la permanencia en la memoria de nuestros lectores a través de nuestros textos y material gráfico. ";
  public dialog = "Para dialogar temas que en naturaleza son distintos";
  public subtitle = "Blog";
  public invitation = "Blog";
  public adventures = "Acompáñanos en nuestras aventuras";
  public convocatorias = "Convocatorias";
  public escritores = {
    uno: "¡Publica tu texto!",
    dos: "Convocatoria abierta para “Horno de reverbero” ",
    tres: "Manda cualquier tipo de obra literaria al correo electrónico ",
    cuatro: "Tema y género libres ",
    cinco: "El archivo deberá tener las siguientes especificaciones:",
    seis: "Documento Microsoft Office Word (.docx)",
    siete: "No mayor a 10 cuartillas",
    ocho: "Utilizar tipografía Times New Roman, 12 puntos, con interlinea de 1.5",
    nueve: "Los textos serán revisados y se contestará en un periodo no mayor a dos meses, en caso de ser publicado. Los textos deben ser inéditos o haber sido publicados en libros de autoría propia (no revistas, ni antologías, etc.), en caso de ser así, deberá notificarse en el cuerpo del correo.",
    diez: "Textos inéditos",
    correo: "revista.reverberante@gmail.com"
  }
  public ilustradores = {
    uno: "Ilustradores y fotógrafos",
    dos: "Convocatoria abierta ",
    tres: "Si te interesa participar y ser publicado en la revista manda tu portafolio al correo electrónico ",
    cuatro: "La colaboración está abierta para mexicanos y extranjeros.",
    cinco: "El material visual deberá ser de creación propia.",
    seis: "De ser seleccionado se le contestará por medio del correo electrónico en un lapso no mayor a 1 semana.",
    correo: "reverberante.dg@gmail.com"
  }
  public magazine = "Consigue la revista física";
  public magazineButton = "¡Quiero adquirir mi revista!";
  public correo = "reverberante.dg@gmail.com"
  public links = [];
  public listaPublicaciones = [];
  public grupos = [];
  public slidePosition = -1;
  public slideToogle: boolean = true;
  public windowObserver$: any;

  constructor(
    private router:Router,
    private linkService:LinksService,
    private metaTitle:Title,
    private meta:Meta,
    private publicaciones: ProjectService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefServiceService
  ) {
    this.links = this.linkService.getLinks();
  }

  ngOnInit() {
    //This section is for handling the meta data for SEO
    this.metaTitle.setTitle('Reverberante | Revista cultural');
    this.meta.addTags([
      { name:'keywords', content:'Reverberante, Revista Reverberante, Revista cultura, Cultura, Magazine, Culture, revista independiente'},
      { name:'description', content:'Revista cultura Reverberante. Como grupo editorial, buscamos la permanencia en la memoria de nuestros lectores a través de nuestros textos y material gráfico'}
    ]);
    //This section is for handling the publication data
    this.publicaciones.getListaPublicaciones().subscribe(items => {
      items.forEach(publicacion => {
        this.listaPublicaciones.push(publicacion.payload.doc.data());
      });
      function comparar(a,b){return a.orden - b.orden;}
      this.listaPublicaciones.sort(comparar);
      this.listaPublicaciones.reverse();
      //console.log(this.listaPublicaciones);
      this.grupos = this.getGroupList3(this.listaPublicaciones);
      //console.log(this.grupos);
    });
    //This section is for handling window resize 
    if(isPlatformBrowser(this.platformId)){
      this.updateSlideWrapper(this.windowRefService.nativeWindow.innerWidth);
      this.windowObserver$ = this.windowRefService.nativeWindow.addEventListener('resize', () => {
        this.updateSlideWrapper(this.windowRefService.nativeWindow.innerWidth);
      });
    }
    // metadatos
    this.meta.addTags([
      { property:'og:image:secure_url', content:"https://drive.google.com/uc?export=view&id=168QpwfbYJcb5oYEIGmHAxxLOXb0D8aTQ"},
      { property:'og:image', content:"https://drive.google.com/uc?export=view&id=168QpwfbYJcb5oYEIGmHAxxLOXb0D8aTQ"},
      { property:'title', content:"Revista Reverberante"}
    ]);
    this.metaTitle.setTitle("Revista Reverberante");
  }

  ngOnDestroy(){
    //this.windowObserver$.unsubscribe();
  }

  public navigateToStore(){
    document.location.href = this.links[4].path;
  }

  public navigateToPublicaciones(){
    document.location.href = this.links[1].path;
  }

  public getGroupList3(publicaciones){
    let numGrupos = Math.ceil(publicaciones.length/3);
    var listaGrupos = [];
    for(var i=0;i<=numGrupos-1;i++){
      var grupo = [];
      for(var j=0;j<=2;j++){
        if(publicaciones[(i*3)+j] != null){
          grupo.push(publicaciones[(i*3)+j]);
        }
      }
      listaGrupos.push(grupo);
    }
    return listaGrupos;
  }

  public getGroupPosition(index){
    //console.log(index);
    return String(index*150)+'%';
  }

  public nextGroup(){
    if(this.slidePosition > -(this.grupos.length-1)){
      this.slidePosition = this.slidePosition - 1;
    } else {
      this.slidePosition = 0;
    }
    //console.log(this.slidePosition);
  }

  public previousGroup(){
    if(this.slidePosition < 0){
      this.slidePosition = this.slidePosition + 1;
    } else {
      this.slidePosition = -(this.grupos.length-1);
    }
    //console.log(this.slidePosition);
  }

  public updateSlideWrapper(size){
    if(size < 769) {
      this.slideToogle = false;
    } else {
      this.slideToogle = true;
    }
  }

  public nextPublication(){
    if(this.slidePosition > -(this.listaPublicaciones.length-1)){
      this.slidePosition = this.slidePosition - 1;
    } else {
      this.slidePosition = 0;
    }
    //console.log(this.slidePosition);
  }

  public previousPublication(){
    if(this.slidePosition < 0){
      this.slidePosition = this.slidePosition + 1;
    } else {
      this.slidePosition = -(this.listaPublicaciones.length-1);
    }
    //console.log(this.slidePosition);
  }

}