<div class="container home">

    <!--=============== Primer fila ===============-->
    <div class="row first-row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-12 center">
        <img src="https://drive.google.com/uc?export=view&id=1NTOPPBlygw0IHl2T6jhKmFsbJU7Gt5n0" alt="">
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-12 center">
        <div class="text-wrapper">
          <div class="red-border-left"></div>
          <div class="red-border-right"></div>
          <h3>{{ title }}</h3>
          <p>{{ description }}</p>
        </div>
      </div>
    </div>
  
  </div>  

  <!--
  <div class="container">
    <ng-adsense
      [adClient]="'ca-pub-2315453864859885'"
      [adSlot]="7519159180"
      [display]="'block'"
      [fullWidthResponsive]="true"
      [adFormat]="'auto'"
    ></ng-adsense>
  </div>
  -->
  
  <div class="container home">

    <!--============ Segunda fila =============-->
    <div class="row second-row">
    <!--level 1-->
      <h3 align="center">{{ invitation }}</h3>
      <!--level 2-->
      <div class="row slide-wrapper">

        <div class="col-1">
          <button *ngIf="slideToogle" class="button-controls arrow-left" (click)="previousGroup()"><img src="../../../assets/images/prev.png" alt=""></button>
          <button *ngIf="!slideToogle" class="button-controls arrow-left" (click)="previousPublication()"><img src="../../../assets/images/prev.png" alt=""></button>
        </div>
        <!-- Slides for desktop -->        
        <div *ngIf="slideToogle" class="col-10 parent-items">

          <div *ngFor="let group of grupos; index as i" class="items-wrapper row" [ngStyle]="{'left':getGroupPosition(i+slidePosition)}">
            <div *ngFor="let publicacion of group" class="col-4">
              <a class="item" [routerLink]="['publicaciones', publicacion.plantilla, publicacion.name]">
                <img src="{{ publicacion.url }}" alt="">
                <p class="name" [innerHTML]="publicacion.name"></p>
                <p class="section" [innerHTML]="publicacion.section"></p>
              </a>
            </div>
          </div>

        </div>
        <!-- Slides for mobile -->
        <div *ngIf="!slideToogle" class="col-10 parent-items">

          <div *ngFor="let publicacion of listaPublicaciones; index as i" class="items-wrapper row" [ngStyle]="{'left':getGroupPosition(i+slidePosition)}">
            <div class="col-12">
              <a class="item" [routerLink]="['publicaciones', publicacion.plantilla, publicacion.name]">
                <img src="{{ publicacion.url }}" alt="">
                <p class="name" [innerHTML]="publicacion.name"></p>
                <p class="section" [innerHTML]="publicacion.section"></p>
              </a>
            </div>
          </div>

        </div>

        <div class="col-1">
          <button *ngIf="slideToogle" class="button-controls arrow-right" (click)="nextGroup()"><img src="../../../assets/images/next.png" alt=""></button>
          <button *ngIf="!slideToogle" class="button-controls arrow-right" (click)="nextPublication()"><img src="../../../assets/images/next.png" alt=""></button>
        </div>

      </div>
      <!--level 3-->
      <h2 align="center">{{ dialog }}</h2>

    </div>
    <!--========== End Segunda fila ===========-->
  
  </div>
  
  <div class="container home">
  
    <!--============== Tercera fila ==============-->
    <div class="row third-row" id="eventos">
  
      <div class="title-wrapper">
        <div class="red-border-left"></div>
        <div class="red-border-right"></div>
        <h3 align="center">{{ adventures }}</h3>
      </div>
  
      <div class="grid">
        <div class="row">
  
          <div class="col-xl-4 col-lg-4 col-12 reduced">
              <div class="col-12"><img src="https://drive.google.com/uc?export=view&id=1rdM3qSTLesjL85OynX_HnBZgbpaunvyT" alt=""></div>
            <div class="col-12"><img src="https://drive.google.com/uc?export=view&id=1fJHiRCX5XKLT4ukX_xjdvjh4f0P3Dy_f" alt=""></div>
          </div>
  
          <div class="col-xl-8 col-lg-8 col-12 large">
            <div class="col-12"><img src="https://drive.google.com/uc?export=view&id=1zWnCEW0X4qT5HbgrmfOaTDRuV2xdw4jX" alt=""></div>
          </div>
  
        </div>
      </div>
  
    </div>
  
  </div>
  
  
  <!--============= Quienta fila ==========-->
  <div class="row fith-row" id="convocatorias">
    <div class="col-xl-6 col-lg-6 col-md-6 col-12 escritores">
      <div class="text-wrapper">
        <h1>{{ convocatorias }}</h1>
        <h2>{{ escritores.uno }}</h2>
        <h3>{{ escritores.dos }}</h3>
        <h4>{{ escritores.tres }}<span>{{ escritores.correo }}</span></h4>
        <h5>{{ escritores.cuatro }}</h5>
        <h5>{{ escritores.diez }}</h5>
        <h6>{{ escritores.cinco }}</h6>
        <ul>
          <li>{{ escritores.seis }}</li>
          <li>{{ escritores.siete }}</li>
          <li>{{ escritores.ocho }}</li>
        </ul>
        <p>{{ escritores.nueve }}</p>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-12 ilustradores">
      <div class="text-wrapper">
        <h1>{{ convocatorias }}</h1>
        <h2>{{ ilustradores.uno }}</h2>
        <h3>{{ ilustradores.dos }}</h3>
        <h4>{{ ilustradores.tres }}<span>{{ ilustradores.correo }}</span></h4>
        <h5>{{ ilustradores.cuatro}}</h5>
        <h6>{{ ilustradores.cinco }}</h6>
        <p>{{ ilustradores.seis }}</p>
      </div>
    </div>
  </div>
  
  
  <!--============= Sexta fila ==========-->
  <div class="container sixth-row-container">
    <div class="row sixth-row">
      <div class="title-wrapper">
        <div class="red-border-left"></div>
        <div class="red-border-right"></div>
        <h3>{{ magazine }}</h3>
      </div>
    </div>
  </div>
  
  <div class="row sixth-row">
  
    <div class="col-12">
      <img src="https://drive.google.com/uc?export=view&id=1vULkihCyVork5M9fIjJHgygm_TL1awI_" alt="">
    </div>
  
    <button class="magazine-button" (click)="navigateToStore()">{{ magazineButton }}</button>
  
  </div>

  <!--
  <div class="container">
    <ng-adsense
      [adClient]="'ca-pub-2315453864859885'"
      [adSlot]="6577434990"
      [display]="'block'"
      [fullWidthResponsive]="true"
      [adFormat]="'auto'"
    ></ng-adsense>
  </div>
  -->