import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ProjectService } from '../../services/project.service';
import { timeout } from 'q';
import { Title, Meta} from '@angular/platform-browser';
import { WindowRefServiceService } from '../../services/window-ref-service.service';

@Component({
  selector: 'app-plantilla1',
  templateUrl: './plantilla1.component.html',
  styleUrls: ['./plantilla1.component.scss']
})
export class Plantilla1Component implements OnInit {

  public recomendacion = "Te recomendamos leer";
  public id;
  public publicacion:any;
  public recomendaciones = [];

  constructor(
    private route: ActivatedRoute,
    private publicaciones: ProjectService,
    private metaTitle: Title,
    private meta: Meta,
    private windowRefService: WindowRefServiceService
  ) { }

  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get('id');
    this.publicaciones.getPublicacion(this.id).subscribe(item => {
      //Información de la publicación
      this.publicacion = item[0].payload.doc.data();
      //.log(this.publicacion.recomendaciones);
      //console.log(this.publicacion.semblanza);
      if(this.publicacion.title == 'Miedo a morir'){
        this.publicacion.title = '¿Miedo a morir?';
      }

      //Información de las recomendaciones
      this.publicacion.recomendaciones.forEach(name => {
        this.publicaciones.getPublicacion(name).subscribe(rec => {
          this.recomendaciones.push(rec[0].payload.doc.data());
          //console.log(this.recomendaciones);
        });
      });

      // Scroll to top page
      this.windowRefService.nativeWindow.scrollTo(0,0);

    });
    
  }

  public refresh() {

    setTimeout(() => {
      window.location.reload();
      this.windowRefService.nativeWindow.scrollTo(0,0);
    },500);

  }

}
