import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AdsenseModule } from 'ng2-adsense';

//Custom components imports
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { Plantilla1Component } from './components/plantilla1/plantilla1.component';
import { Plantilla2Component } from './components/plantilla2/plantilla2.component';
import { Plantilla3Component } from './components/plantilla3/plantilla3.component';
import { PublicacionesComponent } from './components/publicaciones/publicaciones.component';
import { AvisoPrivacidadComponent } from './components/aviso-privacidad/aviso-privacidad.component';

//Services imports
import { ProjectService } from './services/project.service';
import { LinksService } from './services/links.service';
import { WindowRefServiceService } from './services/window-ref-service.service';
import { SortPipe } from './pipes/sort.pipe';

const config = {
  apiKey: "AIzaSyCwKyDsP7SFVRUgYZIm8mUiimsZTDyqO4g",
  authDomain: "reverberante-cdb7f.firebaseapp.com",
  databaseURL: "https://reverberante-cdb7f.firebaseio.com",
  projectId: "reverberante-cdb7f",
  storageBucket: "reverberante-cdb7f.appspot.com",
  messagingSenderId: "902503134895",
  appId: "1:902503134895:web:875b5ad97f5f82e9ad865a",
  measurementId: "G-HF0V76C684"
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HomeComponent,
    NavbarComponent,
    Plantilla1Component,
    Plantilla2Component,
    Plantilla3Component,
    PublicacionesComponent,
    AvisoPrivacidadComponent,
    SortPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    AngularFireModule.initializeApp(config),
     AngularFirestoreModule,
     AdsenseModule.forRoot({
      adClient: 'ca-pub-2315453864859885'
    })
  ],
  providers: [
    ProjectService,
    LinksService,
    WindowRefServiceService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
